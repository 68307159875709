import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import style from "./header.module.scss"

const Header = ({ siteTitle }) => (
    <header className={style.header}>
        <nav className={style.header__nav}>
            <Link to="/photos" className={style.header__navLink}>
                <span className="u-hidden--sm">The </span>photos
            </Link>
            <Link to="/videos" className={style.header__navLink}>
                <span className="u-hidden--sm">The </span>videos
            </Link>
        </nav>
        <h1 className={`c-title--big ${style.header__title}`}>
            <Link to="/">
                {siteTitle}
            </Link>
        </h1>
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
