import React, {Component} from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"
import HeroVideo from "./_index-hero/hero-video"
import Header from "./_header/header"
import Footer from "./_footer/footer"

import indexStyle from "../pages/index.module.scss"
import "../styles/style.scss"
// import SEO from "./seo";


class Layout extends Component {
    render() {
        let {children} = this.props
        let {sectionClass} = this.props
        return (
            <StaticQuery
                query={graphql`
                    query SiteTitleQuery {
                        site {
                            siteMetadata {
                                title
                            }
                        }
                    }
                `}
                render={data => (
                    <div className={sectionClass}>
                        {sectionClass === "index" ? (
                            <>
                                <div className="index__intro">
                                    <div className={indexStyle.heightAdjust}/>
                                    <HeroVideo/>
                                </div>
                                <div className={indexStyle.index__wrapper}>
                                    <Header siteTitle={data.site.siteMetadata.title}/>
                                    <ParallaxProvider>
                                        <main className="wrapper">
                                            {children}
                                        </main>
                                    </ParallaxProvider>
                                    <Footer/>
                                </div>
                            </>
                        ) : (
                            <>
                                <Header siteTitle={data.site.siteMetadata.title}/>
                                <main className="wrapper">
                                    {children}
                                </main>
                                <Footer/>
                            </>
                        )}
                    </div>
                )}
            />
        );
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    sectionClass: PropTypes.node.isRequired,
}

export default Layout
