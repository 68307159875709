import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const StyleGuidePage = () => (
    <Layout sectionClass="styleguide">
        <SEO title="Style guide" />
        <div>
            <h1>Intoxicated Queen Elizabeth vows Mick Jagger is perfection</h1>
            <h2>Intoxicated Queen Elizabeth vows Mick Jagger is perfection</h2>
            <h3>Intoxicated Queen Elizabeth vows Mick Jagger is perfection</h3>
            <h4>Intoxicated Queen Elizabeth vows Mick Jagger is perfection</h4>
            <h5>Intoxicated Queen Elizabeth vows Mick Jagger is perfection</h5>
            <h6>Intoxicated Queen Elizabeth vows Mick Jagger is perfection</h6>
            <p className="c-copy">Research from Brown University has linked the presence of congeners in alcohol to headaches and other symptoms. Congeners are basically any toxins in an alcoholic beverage besides the alcohol itself. Grape skins — as well as barrel aging and additives — can increase a wine’s congener content, the Brown study finds. And so it’s possible that some of the additives or processes that natural winemakers eschew could increase a conventional wine’s congener content, and therefore its headache- or hangover-inducing potential. But it’s also possible that many unoaked conventional wines may have fewer congeners than oak-aged natural wines. None of this has been studied directly, so no one knows.</p>
            <p>Research from Brown University has linked the presence of congeners in alcohol to headaches and other symptoms. Congeners are basically any toxins in an alcoholic beverage besides the alcohol itself. Grape skins — as well as barrel aging and additives — can increase a wine’s congener content, the Brown study finds. And so it’s possible that some of the additives or processes that natural winemakers eschew could increase a conventional wine’s congener content, and therefore its headache- or hangover-inducing potential. But it’s also possible that many unoaked conventional wines may have fewer congeners than oak-aged natural wines. None of this has been studied directly, so no one knows.</p>
            <small>Research from Brown University has linked the presence of congeners in alcohol to headaches and other symptoms. Congeners are basically any toxins in an alcoholic beverage besides the alcohol itself. Grape skins — as well as barrel aging and additives — can increase a wine’s congener content, the Brown study finds. And so it’s possible that some of the additives or processes that natural winemakers eschew could increase a conventional wine’s congener content, and therefore its headache- or hangover-inducing potential. But it’s also possible that many unoaked conventional wines may have fewer congeners than oak-aged natural wines. None of this has been studied directly, so no one knows.</small>
            <h6 className="c-title--huge">Intoxicated Queen Elizabeth vows Mick Jagger is perfection</h6>
            <h6 className="c-title--super">Intoxicated Queen Elizabeth vows Mick Jagger is perfection</h6>
            <h6 className="c-title--big">Intoxicated Queen Elizabeth vows Mick Jagger is perfection</h6>
            <h6 className="c-title--normal">Intoxicated Queen Elizabeth vows Mick Jagger is perfection</h6>
            <h6 className="c-title--subtitle">Intoxicated Queen Elizabeth vows Mick Jagger is perfection</h6>
        </div>
        <ul>
            <li className="color color--ink"/>
            <li className="color color--white"/>
            <li className="color color--gray"/>
            <li className="color color--gray--light"/>
            <li className="color color--offwhite"/>
            <li className="color color--gray--dark"/>
            <li className="color color--wine--riesling"/>
            <li className="color color--wine--pinot"/>
            <li className="color color--wine--blanc"/>
            <li className="color color--wine--rose"/>
            <li className="color color--wine--sherry"/>
            <li className="color color--wine--cabernet"/>
            <li className="color color--wine--merlot"/>
            <li className="color color--wine--syrah"/>
        </ul>
        <Link to="/">Go back to the homepage</Link>
    </Layout>
)

export default StyleGuidePage
