import React, {Component} from "react"
import {Link} from "gatsby"
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Logo from "../_logo/logo"
import style from "./footer.module.scss"

class Footer extends Component {
    render() {
        return (
            <footer className={style.footer}>
                <div className={`u-txt--center ${style.footer__content}`}>
                    <Link className={style.footer__link} to="/">
                        <div className={style.logo__wrapper}>
                            <Logo logoClass={style.logoFooter}/>
                        </div>
                        <h4 className={`c-title--big u-mgt--1 ${style.siteTitle}`}>
                            XENIOS’ 40TH
                        </h4>
                        <h5>Beaune, 2nd — 6th May 2019</h5>
                    </Link>
                    <div className={style.footer__copyright}>
                        <small>© {new Date().getFullYear()}, Xenios Thrasyvoulou</small>
                    </div>
                </div>
                <div className={style.footer__pseudobackground}/>
                <small className={style.footer__credits}>Site by <OutboundLink className={style.link} href="https://teyepe.com" rel="noopener noreferrer" target="_blank">teyepe</OutboundLink></small>
            </footer>
        );
    }
}

export default Footer
