import React, {Component} from "react"
import classNames from "classnames/bind"
import Logo from "../_logo/logo"
import videoPosterJpg from "../../../content/videos/intro/Xenios40.jpg"
import videoPosterMp4 from "../../../content/videos/intro/Xenios40.mp4"
import videoPosterWebm from "../../../content/videos/intro/Xenios40.webm"
import style from "./hero-video.module.scss"

const cx = classNames.bind(style);
class HeroVideo extends Component {
    constructor(props) {
        super(props)
        this.controlVideo = this.controlVideo.bind(this)
        this.state = {
            playing: false,
            ended: false
        }
    }
    controlVideo() {
        const { playing } = this.state,
            toPlay = document.getElementById('from_pause_to_play'),
            toPause = document.getElementById('from_play_to_pause')
        if (playing) {
            if('beginElement' in toPlay) {
                toPlay.beginElement()
            }
            this.refs.vidRef.pause()
            this.setState({
                playing: false
            })
        } else {
            if('beginElement' in toPause) {
                toPause.beginElement()
            }
            this.refs.vidRef.play()
            this.setState({
                playing: true
            })
        }
    }
    exitFullscreen = () => {
        const exit = document.webkitExitFullscreen ?
            document.webkitExitFullscreen.bind(document) :
            document.mozCancelFullscreen ?
                document.mozCancelFullscreen.bind(document) :
                document.msExitFullscreen ?
                    document.msExitFullscreen.bind(document) :
                    document.exitFullscreen ?
                        document.exitFullscreen.bind(document) :
                        () => {}
        exit()
    }

    videoEnded = () => {
        this.refs.vidRef.currentTime = 0
        this.refs.vidRef.pause()
        this.setState({
            playing: false
        })
        this.exitFullscreen()
    };

    componentDidMount() {
        this.refs.vidRef.onended = () => {
            this.setState({
                playing: false,
                ended: true
            })
        }
    }

    render() {
        const {playing, ended} = this.state
        const contentClass = cx({
            video__content: true,
            isStreaming:    playing,
            hasEnded:       ended && !playing
        })
        const playerClass = cx({
            video__control: true,
            isStreaming:    playing,
            hasEnded:       ended && !playing
        })
        const textClass = cx({
            hero__textBlock: true,
            isStreaming:     playing,
            hasEnded:        ended && !playing
        })
        const moveElem = e => {
            let cursor = e || window.event
            let btn = document.getElementById('custom-play')
            btn.style.left = cursor.clientX + 'px'
            btn.style.top = cursor.clientY + 'px'
        }
        const paths = {
            play: 'M 26,38 32.5,34 32.5,26 26,22 z M 32.5,34 39,30 39,30 32.5,26 z',
            pause: 'M 24,38 28,38 28,22 24,22 z M 33,38 37,38 37,22 33,22 z',
        }
        return (
            <section className={`${style.hero} ${style.heroVideo}`} onMouseMove={moveElem}>
                <div className={textClass}>
                    <div className={`u-hidden--sm u-hidden--md ${style.hero__logoWrapper}`}>
                        <Logo logoClass={style.logoHero}/>
                    </div>
                    <div className={style.hero__titleWrapper}>
                        <h4 className={`c-title--super ${style.hero__title}`}>Beaune</h4>
                        <h5 className={style.hero__subtitle}>2nd — 6th May 2019</h5>
                    </div>
                    <div className={`u-hidden--sm u-hidden--md ${style.overlay}`} />
                </div>
                <div className={contentClass}>
                    <video className={style.video} playsInline preload="auto" poster={videoPosterJpg} ref="vidRef" onEnded={this.videoEnded}>
                        <source src={videoPosterMp4} type="video/mp4"/>
                        <source src={videoPosterWebm} type="video/webm"/>
                    </video>
                    <div id="custom-play" className={playerClass} onClick={this.controlVideo} >
                        <svg
                            id="play_pause"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 60 60"
                            enableBackground="new 0 0 60 60"
                            xmlSpace="preserve"
                        >
                            <path fill="#303030" id="shape" d={paths.play}>
                                <animate
                                    attributeName="d"
                                    begin="play_pause.click"
                                    fill="freeze"
                                    id="from_pause_to_play"
                                    dur="200ms"
                                    from={paths.pause}
                                    to={paths.play}
                                />
                            </path>
                            <animate
                                xlinkHref="#shape"
                                attributeName="d"
                                begin="indefinite"
                                fill="freeze"
                                id="from_play_to_pause"
                                dur="180ms"
                                from={paths.play}
                                to={paths.pause}
                            />
                        </svg>
                    </div>
                </div>
            </section>
        );
    }
}

export default HeroVideo
