import React from "react"
import PropTypes from "prop-types"
import style from "./logo.module.scss"

const Logo = ({ logoClass}) => (
    <svg id="logo"
         className={logoClass}
         xmlns="http://www.w3.org/2000/svg"
         x="0px" y="0px"
         width="234px" height="234px"
         viewBox="0 0 234 234"
         xmlSpace="preserve"
    >
        <g className={style.logo}>
            <circle className={style.logo__stroke} cx="117" cy="117" r="114"/>
            <path className={style.logo__fill}  d="M144.7,52.1h37.4l-42.6,36.2L144.7,52.1L144.7,52.1z M89.8,181.7H50l45.6-40.1L89.8,181.7L89.8,181.7z
                 M53.4,52.1h45l85.1,130h-47.9L53.4,52.1L53.4,52.1z"/>
        </g>
    </svg>
)

Logo.propTypes = {
    logoClass: PropTypes.string,
}

export default Logo
